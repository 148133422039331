







































































import Vue from "vue";
import businessintelligence from "../assets/businessintelligence.png";
import management from "../assets/management.png";
import paperplane from "../assets/paper-plane.png";
import contact from "../assets/contact.png";
import OnneHomeCardItem from "@/components/OnneHomeCardItem.vue";

export default Vue.extend({
  components: { OnneHomeCardItem },
  data: () => ({
    textotitulo: [
      "Soluções Tecnológicas",
      "Bussines Inteligence",
      "Desenvolvimento",
    ],
    cards: [
      {
        titulo: "TechOnne",
        subtitulo: "Support",
        imagem: management,
      },
      {
        titulo: "TechOnne",
        subtitulo: "Insights",
        imagem: businessintelligence,
      },
      {
        titulo: "TechOnne",
        subtitulo: "Fly",
        imagem: paperplane,
      },
      {
        titulo: "Entre em contato",
        imagem: contact,
      },
    ],
  }),
});
